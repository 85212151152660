
export class Archiv {
  id: number
  archivname: string
  mandantid: number
  mandant: string

  constructor () {
    this.id = 0;
    this.archivname = "";
    this.mandantid = 0;
    this.mandant = "";
  }
}

export class Mandant {
  id: number
  name: string

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}
