import { Stack } from "@/models/Helper"
import { Ordner } from "@/models/OrdnerModels"

export type State = {
  ordner: Array<Ordner>
  currentOrdner: Array<Ordner>
}

export const state: State = {
  ordner: new Array<Ordner>(),
  currentOrdner: new Array<Ordner>()
}
