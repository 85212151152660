<template>
  <div class="row p-2 folder-row">
    <div class="col-7">
      <input :id="'folder-row-select_' + ord.id" class="form-check-input me-4" type="checkbox" value="">

      <div v-if="deleteOrdnerLoading && thisOrdnerDeleteLoading" class="spinner-border spinner-border-sm text-primary me-4"></div>
      <font-awesome-icon v-else icon="folder-open" class="text-primary me-4"/>
      
      <span @click="getOrdnerElements" class="folder-titel me-4">{{ ord.titel }}</span>
    </div>
    <div class="col-1">
      <div class="dropdown">
        <button type="button" class="btn btn-sm py-0 folder-menu-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><font-awesome-icon icon="ellipsis" class="text-body-tertiary" /></button>

        <ul class="dropdown-menu m-0 p-0 rounded-0">
          <li class="dropdown-item" @click="showUpdateModal()">
            Bearbeiten
          </li>
          <li class="dropdown-item" @click="showDeleteModal()">
            Löschen
          </li>
        </ul>
      </div>
    </div>
    <div class="col-2">
      {{ ord.barcode }}
    </div>
    <div class="col-2 text-end">
      <span>{{ dateConverter }}</span>
    </div>
  </div>
  <!-- <button type="button" class="btn btn-outline-secondary btn-sm" @click="showUpdateModal()">Update</button>
  <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteOrdner()">Löschen</button> -->
  
  <!-- Update Modal -->
  <div
    class="modal fade"
    :id="'updateOrdnerModal' + ord.id"
    tabindex="-1"
    :aria-labelledby="'updateOrdnerModal' + ord.id"
    aria-hidden="true"
    ref="updateOrdnerModalRef"
    >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header py-1">
          <span class="modal-title my-auto" :id="'updateOrdnerModalLabel' + ord.id">
            Ordner bearbeiten
          </span>

          <button
            type="button"
            class="btn-close btn-sm"
            @click="closeUpdateModal()"
          ></button>
        </div>

        <div class="modal-body" :id="'updateOrdnerModalBody' + ord.id">
          <LoadingSpinner v-if="updateLoading" />

          <div v-else>
            <div class="mb-3">
              <label :for="'updateFolderTitelInput_' + ord.id" class="form-label">Titel</label>
              <input type="text" class="form-control" :id="'updateFolderTitelInput_' + ord.id" v-model="updateOrdner.titel">
            </div>

            <div class="mb-3">
              <label :for="'updateFolderTitel_1Input_' + ord.id" class="form-label">Titel 1</label>
              <input type="text" class="form-control" :id="'updateFolderTitel_1Input_' + ord.id" v-model="updateOrdner.titel_1">
            </div>

            <div class="mb-3">
              <label :for="'updateFolderTitel_2Input_' + ord.id" class="form-label">Titel 2</label>
              <input type="text" class="form-control" :id="'updateFolderTitel_2Input_' + ord.id" v-model="updateOrdner.titel_2">
            </div>

            <div class="mb-3">
              <label :for="'updateFolderTitel_3Input_' + ord.id" class="form-label">Titel 3</label>
              <input type="text" class="form-control" :id="'updateFolderTitel_3Input_' + ord.id" v-model="updateOrdner.titel_3">
            </div>

            <div class="mb-3">
              <label :for="'updateFolderTitel_4Input_' + ord.id" class="form-label">Titel 4</label>
              <input type="text" class="form-control" :id="'updateFolderTitel_4Input_' + ord.id" v-model="updateOrdner.titel_4">
            </div>

            <div class="mb-3">
              <label :for="'updateFolderTitel_5Input_' + ord.id" class="form-label">Titel 5</label>
              <input type="text" class="form-control" :id="'updateFolderTitel_5Input_' + ord.id" v-model="updateOrdner.titel_5">
            </div>

            <div class="mb-3">
              <label :for="'updateFolderBarcodeInput_' + ord.id" class="form-label">Barcode</label>
              <input type="text" class="form-control" :id="'updateFolderBarcodeInput_' + ord.id" v-model="updateOrdner.barcode">
            </div>
          </div>

          <div v-if="updateErrorMsg != null && updateErrorMsg != '' && showUpdateErrorMsg" class="alert alert-danger">
              <!-- {{ updateErrorMsg }} -->
              <!-- <br> -->
              Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
            </div>
        </div>

        <div class="modal-footer" v-if="!updateLoading">
          <button
            type="button"
            class="btn btn-secondary btn-sm"
            @click="closeUpdateModal()"
            >
            Abbrechen
          </button>
          <button type="button" class="btn btn-primary btn-sm" @click="saveChanges()">
            Änderungen speichern
          </button>
        </div>
      </div>
    </div>
  </div>

    <!-- Delete Modal -->
  <div
    class="modal fade"
    :id="'deleteOrdnerModal' + ord.id"
    tabindex="-1"
    :aria-labelledby="'deleteOrdnerModal' + ord.id"
    aria-hidden="true"
    ref="deleteOrdnerModalRef"
    >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-danger text-white py-1">
          <strong class="modal-title my-auto" :id="'deleteOrdnerModalLabel' + ord.id">
            Ordner löschen
          </strong>

          <button
            type="button"
            class="btn-close btn-sm"
            @click="closeDeleteModal()"
          ></button>
        </div>

        <div class="modal-body" :id="'deleteOrdnerModalBody' + ord.id">
          <LoadingSpinner v-if="deleteOrdnerLoading" />

          <div v-else>
            <p>Der Ordner <strong class="text-danger">{{ ord.titel }}</strong> sowie alle Unterordner und Dokumente werden gelöscht</p>
          </div>

          <div v-if="deleteOrdnerErrorMsg != null && deleteOrdnerErrorMsg != '' && showDeleteErrorMsg" class="alert alert-danger">
            <!-- {{ updateErrorMsg }} -->
            <!-- <br> -->
            Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
          </div>
        </div>

        <div class="modal-footer" v-if="!deleteOrdnerLoading">
          <button
            type="button"
            class="btn btn-secondary btn-sm"
            @click="closeDeleteModal()"
            >
            Abbrechen
          </button>
          <button type="button" class="btn btn-danger btn-sm" @click="deleteOrdner()">
            Ordner löschen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import LoadingSpinner from "@/components/LoadingSpinner.vue"

import { defineComponent, PropType, computed, ref, reactive } from "vue";
import { Modal } from 'bootstrap';

import { GetOrdnerRequest, Ordner, UpdateOrdnerRequest, DeleteOrdnerRequest } from '@/models/OrdnerModels';
import { GetDocsRequest } from '@/models/DocModels';
import { useStore } from "vuex";
import { ActionTypes as OrdnerActionTypes } from "@/store/modules/Ordner/actions";
import { ActionTypes as DocActionTypes } from "@/store/modules/Doc/actions";

export default defineComponent({
  name: "Ordnercomp",

  components: {
    LoadingSpinner
  },

  props: {
    ord: {
      type: Object as PropType<Ordner>,
      required: true
    },
    archivid: {
      type: Number,
      required: true
    },
  },

  setup (props) {
    const store = useStore();

    const dateConverter = computed(() => {
      if (props.ord.ddate != null) {
        const ddate = new Date(props.ord.ddate);
        return ("0" + ddate.getDate()).slice(-2) + "." + ("0" + (ddate.getMonth() + 1)).slice(-2) + "." + ddate.getFullYear();
      }
      else {
        return ""
      }
    })

    // Update Ordner Modal
    const updateLoading = computed(() => store.getters.status.updateOrdnerLoading)
    const updateErrorMsg = computed(() => store.getters.status.updateOrdnerErrorMsg)
    const showUpdateErrorMsg = ref(false)

    const updateOrdner = reactive({
      titel: props.ord.titel,
      titel_1: props.ord.titel_1,
      titel_2: props.ord.titel_2,
      titel_3: props.ord.titel_3,
      titel_4: props.ord.titel_4,
      titel_5: props.ord.titel_5,
      barcode: props.ord.barcode
    })

    function resetForm () {
      updateOrdner.titel = props.ord.titel,
      updateOrdner.titel_1 = props.ord.titel_1,
      updateOrdner.titel_2 = props.ord.titel_2,
      updateOrdner.titel_3 = props.ord.titel_3,
      updateOrdner.titel_4 = props.ord.titel_4,
      updateOrdner.titel_5 = props.ord.titel_5,
      updateOrdner.barcode = props.ord.barcode
    } 

    const updateOrdnerModalRef = ref()
    function closeUpdateModal() {
      resetForm();
      Modal.getInstance(updateOrdnerModalRef.value)?.hide();
    }
    async function showUpdateModal() {
      showUpdateErrorMsg.value = false;

      if (updateOrdnerModalRef.value) {
        new Modal(updateOrdnerModalRef.value).show();
      }
    }

    // Delete Ordner
    const deleteOrdnerLoading = computed(() => store.getters.status.deleteOrdnerLoading)
    const thisOrdnerDeleteLoading = ref(false)
    const deleteOrdnerErrorMsg = computed(() => store.getters.status.deleteOrdnerErrorMsg)
    const showDeleteErrorMsg = ref(false)

    const deleteOrdnerModalRef = ref()
    function closeDeleteModal() {
      Modal.getInstance(deleteOrdnerModalRef.value)?.hide();
    }
    async function showDeleteModal() {
      showDeleteErrorMsg.value = false;

      if (deleteOrdnerModalRef.value) {
        new Modal(deleteOrdnerModalRef.value).show();
      }
    }

    return {
      store,
      dateConverter,
      updateLoading,
      updateErrorMsg,
      showUpdateErrorMsg,
      updateOrdner,
      updateOrdnerModalRef,
      closeUpdateModal,
      showUpdateModal,
      deleteOrdnerLoading,
      thisOrdnerDeleteLoading,
      deleteOrdnerErrorMsg,
      showDeleteErrorMsg,
      deleteOrdnerModalRef,
      closeDeleteModal,
      showDeleteModal
    }
  },

  methods: {
    getOrdnerElements () {
      this.store.dispatch(OrdnerActionTypes.SetCurrentOrdner, this.ord);

      if (this.archivid && (this.ord.ordner == null || this.ord.ordner?.length == 0)) {
        this.store.dispatch(OrdnerActionTypes.GetOrdner, new GetOrdnerRequest(this.archivid, this.ord.ordid));
      }

      if (this.archivid && (this.ord.docs == null || this.ord.docs?.length == 0)) {
        this.store.dispatch(DocActionTypes.GetDocs, new GetDocsRequest(this.archivid, this.ord.ordid));
      }
    },

    saveChanges () {
      this.showUpdateErrorMsg = false;

      const request: UpdateOrdnerRequest = {
        ordid: this.ord.ordid,
        archivid: this.archivid,
        titel: this.updateOrdner.titel,
        titel_1: this.updateOrdner.titel_1,
        titel_2: this.updateOrdner.titel_2,
        titel_3: this.updateOrdner.titel_3,
        titel_4: this.updateOrdner.titel_4,
        titel_5: this.updateOrdner.titel_5,
        barcode: this.updateOrdner.barcode
      }

      this.store.dispatch(OrdnerActionTypes.UpdateOrdner, request)
      .then(() => {
        if (this.updateErrorMsg == null || this.updateErrorMsg == "") {
          this.closeUpdateModal();
        }
        else {
          this.showUpdateErrorMsg = true;
        }
      })
    },

    deleteOrdner () {
      this.thisOrdnerDeleteLoading = true
      const request = new DeleteOrdnerRequest(this.ord.ordid, this.archivid)
      this.closeDeleteModal();
      this.store.dispatch(OrdnerActionTypes.DeleteOrdner, request)
      .then(() => {
        if (this.deleteOrdnerErrorMsg != null && this.deleteOrdnerErrorMsg != "") {
          this.showDeleteModal();
          this.showDeleteErrorMsg = true;
        }
      })
      .finally(() => {
        this.thisOrdnerDeleteLoading = false
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.folder-row:hover {
  background: #f8f9fa;
}
.folder-titel:hover {
  cursor: pointer;
  text-decoration: underline;
}

.folder-row .folder-menu-btn {
  visibility: hidden;
}

.folder-row:hover .folder-menu-btn {
  visibility: visible;
}

.folder-menu-btn:hover {
  background: rgb(221, 221, 221);
}

.folder-menu-btn:active {
  background: rgb(221, 221, 221) !important;
  border-color: transparent !important;
}

.folder-menu-btn:focus {
  background: rgb(221, 221, 221) !important;
  border-color: transparent !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-item:hover {
  cursor: pointer;
  background-color: rgb(221, 221, 221);
}
</style>
