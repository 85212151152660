<template>
  <div class="d-flex justify-content-between align-items-center p-2 doc-row">
    <input class="form-check-input me-4" type="checkbox" value="">
    <font-awesome-icon v-if="contenttype === 'pdf'" icon="file-pdf" class="me-4"/>
    <font-awesome-icon v-else-if="contenttype === 'txt'" icon="file" class="me-4"/>
    <font-awesome-icon v-else-if="contenttype === 'jpg' || contenttype === 'png' || contenttype === 'tif'" icon="file-image" class="me-4"/>
    <font-awesome-icon v-else-if="contenttype === 'doc' || contenttype === 'docx'" icon="file-word" class="me-4"/>
    <font-awesome-icon v-else-if="contenttype === 'xlsx'" icon="file-excel" class="me-4"/>
    <font-awesome-icon v-else-if="contenttype === 'pptx'" icon="file-powerpoint" class="me-4"/>
    <font-awesome-icon v-else icon="file" class="me-4"/>
    <span @click="downloadComponent" class="doc-titel flex-fill me-4">{{ comp.filename }}</span>
    <span>{{ dateConverter }}</span>
  </div>

  <!-- PDF Preview Modal -->
  <div
    class="modal fade p-0"
    :id="'ordnerDokumentPDFModal' + comp.id"
    tabindex="-1"
    :aria-labelledby="'ordnerDokumentPDFModal' + comp.id"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="ordnerDokumentPDFModalRef"
    >
    <div class="modal-dialog modal-dialog-centerd modal-xl my-0 h-100">
      <div class="modal-content h-100">
        <div class="modal-header py-1">
          <div class="w-100 d-flex justify-content-between me-3">
            <h5 class="modal-title my-auto" :id="'ordnerDokumentPDFModalLabel' + comp.id">
              {{comp.titel}}
            </h5>
            
            <!-- <button class="btn btn-outline-dark my-auto" @click="showSendEmailModal()">
              <font-awesome-icon icon="envelope" class="me-3"/>
              An private E-Mail senden
            </button> -->
          </div>
          <button
          type="button"
          class="btn-close"
          @click="closePdfModal()"
          ></button>
        </div>

        <div class="modal-body p-0" :id="'ordnerDokumentPDFModalBody' + comp.id">
          <div v-if="comploaded">
            <PDFViewer 
              ref="pdfViewerRef"
              :pdfUrl="pdfUrl"
              :docId="comp.id.toString()"
              :pdfName="componente.filename"
              :options="pdfViewerOptions"
              @sendDocAsEmail="showSendEmailModal()" />
          </div>
          <div v-if="loading">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from "vue";
import { Modal } from 'bootstrap';

import PDFViewer from "../PDFViewer/PDFViewer.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { PDFViewerOptions } from '@/components/PDFViewer/PDFViewerClasses'

import { Comp, DownloadCompData } from '@/models/CompModels';
import { GetCompsRequest } from '@/models/CompModels';
import { useStore } from "vuex";
import { ActionTypes as CompActionTypes } from "@/store/modules/Comp/actions";

const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 
const PDFVIEWERDEFAULTSCALE = config.pdfViewerDefaultScale;

export default defineComponent({
  name: "CompComponent",

  props: {
    comp: {
      type: Object as PropType<Comp>,
      required: true
    },
    archivid: {
      type: Number,
      required: true
    },
    archivname: {
      type: String,
      required: true
    },
    mandantid: {
      type: Number,
      required: true
    }
  },

  components: {
    PDFViewer,
    LoadingSpinner
  },

  setup (props) {
    const store = useStore();

    const loading = computed(() => store.getters.status.compDownloadLoading)
    const componente = computed(() => store.getters.componente);
    const comploaded = ref(false)

    const pdfUrl =ref("")
    const pdfViewerOptions = ref(new PDFViewerOptions())

    const dateConverter = computed(() => {
      const mdate = new Date(props.comp.mdate);
      return ("0" + mdate.getDate()).slice(-2) + "." + ("0" + (mdate.getMonth() + 1)).slice(-2) + "." + mdate.getFullYear() + " " + ("0" + (mdate.getHours() + 1)).slice(-2) + ":" + ("0" + (mdate.getMinutes() + 1)).slice(-2) + ":" + ("0" + (mdate.getSeconds() + 1)).slice(-2);
    })

    const contenttype = computed(() => props.comp.filename.split(".")[1].toLowerCase())

    const pdfViewerRef = ref();
    const ordnerDokumentPDFModalRef = ref();
    const closeModal = () => {
      pdfViewerRef.value?.clearViewer()
      Modal.getInstance(ordnerDokumentPDFModalRef.value)?.hide();
    }
    const showModal = async () => {
      if (ordnerDokumentPDFModalRef.value) {
        new Modal(ordnerDokumentPDFModalRef.value).show();

        // await pdfViewerRef.value.loadPDF()
      }
    }

    return {
      store,
      loading,
      comploaded,
      pdfUrl,
      pdfViewerOptions,
      dateConverter,
      contenttype,
      componente,
      ordnerDokumentPDFModalRef,
      closeModal,
      showModal,
    }
  },

  methods: {
    downloadComponent () {
      if ((this.comp.contenttype && this.comp.contenttype.toLowerCase() == ".pdf") || (this.comp.filename.toLowerCase().includes(".pdf"))) {
        this.showModal()
      }

      this.comploaded = false

      const data: DownloadCompData = {
        archivmandantid: this.mandantid,
        archivname: this.archivname,
        componente: this.comp
      }

      this.store.dispatch(CompActionTypes.DownloadComp, data)
        .then(() => {
          if ((this.componente.contenttype && this.componente.contenttype.toLowerCase() == ".pdf") || (this.componente.filename.toLowerCase().includes(".pdf"))) {
            this.pdfUrl = this.base64PDFToBlobUrl(this.componente.file.filecontentAsBase64)
            this.comploaded = true
          }
          else {
            const url = this.base64PDFToBlobUrl(this.componente.file.filecontentAsBase64)
            this.saveInFileSystem(url, this.componente.filename)
          }
        })
    },

    closePdfModal () {
      this.closeModal();

      // this.store.dispatch(ArchivActionTypes.ArchivClearComponentenCache, undefined);
    },

    async saveInFileSystem(blobUrl: string, fileName: string) {
      const a = document.createElement("a");
      if (!a.click) {
        throw new Error('DownloadManager: "a.click()" is not supported.');
      }
      a.href = blobUrl;
      a.target = "_parent";
      if ("download" in a) {
        a.download = fileName;
      }
      (document.body || document.documentElement).append(a);
      a.click();
      a.remove();
    },

    base64PDFToBlobUrl(base64: string) {
      const binStr = atob( base64 );
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[ i ] = binStr.charCodeAt( i );
      }
      const blob =  new Blob([ arr ]);
      const url = URL.createObjectURL( blob );
      return url;
    },

    showSendEmailModal() {
      console.log("not implemented yet.");
      // this.showError = false
      // this.showEmailModal()
    },

    closeSendEmailModal() {
      console.log("not implemented yet.");
      // this.privateEmail = this.personaldaten.email
      // this.closeEmailModal()
    },

    sendmail () {
      console.log("not implemented yet.");
      // const data: SendDocAsMailRequest = {
      //   archivmandant: this.personaldaten.archivmandant,
      //   archivname: this.personaldaten.archivname,
      //   docguid: this.dokument.componente.docid.trim(),
      //   compguid: this.dokument.componente.compid.trim(),
      //   filename: this.dokument.componente.filename.trim(),
      //   archivtoken: this.archivUser.token,
      //   responseEmailAdresse: this.privateEmail
      // };

      // this.store.dispatch(PersonalActionTypes.SendDocAsMail, data)
      //   .then(() => {
      //     if (this.emailErrorMsg == null || this.emailErrorMsg == "") {
      //       this.closeEmailModal()
      //     }
      //     else {
      //       this.showError = true
      //     }
      //   });
    }
  },

  mounted () {
    this.pdfViewerOptions.defaultScale = PDFVIEWERDEFAULTSCALE
    this.pdfViewerOptions.showEmailSendenOption = false
  }
})
</script>

<style lang="scss" scoped>
.doc-row:hover {
  background: #f8f9fa
}

.doc-titel:hover {
  cursor: pointer;
  text-decoration: underline;
}

.modal {
  height: 100% !important;
  /* overflow: hidden; */
}

</style>