import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";

import { RootState } from '@/store';
import { Actions as OrdnerActions, ActionTypes as OrdnerActionTypes } from "../Ordner/actions";
import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions";

import { State } from "./state"
import { Comp, GetCompsRequest, DownloadCompData, DownloadCompRequest } from "@/models/CompModels";
import ArchivApiService from "@/service/ArchivApiService";

export enum ActionTypes {
  GetComps = "GETCOMPS",
  DownloadComp = "DOWNLOADCOMP"
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetComps](context: ActionArguments, data: GetCompsRequest): void
  [ActionTypes.DownloadComp](context: ActionArguments, data: DownloadCompData): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.GetComps]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status
    status.compLoading = true
    status.compLoadingDocid = data.docid;
    status.compErrorMsg == ""
    dispatch(CommonActionTypes.SetStatus, status);
    
    ArchivApiService.getComps(data)
      .then(res => {
        dispatch(OrdnerActionTypes.SetOrdnerDocComps, res.data)
        
      })
      .catch(error => {
        status.compErrorMsg == error.toString()
      })
      .finally(() => {
        status.compLoading = false
        status.compLoadingDocid = "";
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DownloadComp]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status
    status.compDownloadLoading = true
    status.compErrorMsg == ""
    dispatch(CommonActionTypes.SetStatus, status);

    const requestData: DownloadCompRequest = new DownloadCompRequest(data.archivmandantid, data.archivname, data.componente.docid, data.componente.compid);
    // const componente: Comp = data.componente;
    const componente: Comp = JSON.parse(JSON.stringify(data.componente));
    
    return ArchivApiService.getCompFile(requestData)
      .then(res => {
        componente.file = res.data
        commit(MutationTypes.ComponentenSuccess, componente);
      })
      .catch(error => {
        console.log(error);
        const status = rootGetters.status
        status.compErrorMsg == error.toString()
      })
      .finally(() => {
        status.compDownloadLoading = false
        dispatch(CommonActionTypes.SetStatus, status);
      })
  }
}