<template>
  <!-- <div class="d-flex justify-content-between align-items-center p-2 doc-row">
    <input class="form-check-input me-4" type="checkbox" value="">
    <font-awesome-icon icon="folder" class="me-4"/>
    <span @click="getDocComponents" class="doc-titel flex-fill me-4"></span>
    <span class="me-4"></span>
    <span></span>
  </div> -->

  <div class="row p-2 doc-row" @click="toggleCompsArea()">
    <div class="col-8">
      <input class="form-check-input me-4" type="checkbox" value="">
      <font-awesome-icon icon="folder" class="me-4"/>
      <span class="doc-titel me-2" @click="getDocComponents">{{ doc.titel }}</span>
    </div>
    <div class="col-2">
      <span>{{ doc.barcode }}</span>
    </div>
    <div class="col-2 text-end">
      <span>{{ dateConverter }}</span>
    </div>
  </div>

  <div v-if="currentCompLoading" class="spinner-border spinner-border-sm text-primary d-block mx-auto my-2" role="status">
    <span class="visually-hidden"></span>
  </div>

  <div v-else-if="doc.comps != null && doc.comps.length > 0 && showCompsArea" class="px-3 py-2">
    <div v-for="comp in doc.comps" :key="comp.id">
      <ComponentComp :comp="comp" :archivid="archivid || 0" :archivname="archivname || ''" :mandantid="mandantid || 0" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref, watchEffect } from "vue";

import { Doc, GetDocsRequest } from '@/models/DocModels';
import { GetCompsRequest } from '@/models/CompModels';
import { useStore } from "vuex";
import { ActionTypes as CompActionTypes } from "@/store/modules/Comp/actions";

import ComponentComp from "@/components/Archiv/ComponentComp.vue"

export default defineComponent({
  name: "Dokument",

  components: {
    ComponentComp
  },

  props: {
    doc: {
      type: Object as PropType<Doc>,
      // default: new Doc()
      required: true
    },
    archivid: Number,
    archivname: String,
    mandantid: Number,
  },

  setup (props) {
    const store = useStore();

    const compLoading = computed(() => store.getters.status.compLoading)
    const compLoadingDocid = computed(() => store.getters.status.compLoadingDocid)
    const currentCompLoading = computed(() => compLoading.value && compLoadingDocid.value == props.doc.docid)

    const showCompsArea = ref(false)

    const dateConverter = computed(() => {
      if (props.doc.ddate != null) {
        const ddate = new Date(props.doc.ddate);
        return ("0" + ddate.getDate()).slice(-2) + "." + ("0" + (ddate.getMonth() + 1)).slice(-2) + "." + ddate.getFullYear();
      }
      else {
        return "";
      }
    })

    const getDocComponents = () => {
      if (props.archivid && props.doc.docid != "") {
        store.dispatch(CompActionTypes.GetComps, new GetCompsRequest(props.archivid, props.doc.docid));
      }
    }

    const toggleCompsArea = () => {
      showCompsArea.value = !showCompsArea.value

      if (showCompsArea.value && (!props.doc.comps || props.doc.comps.length == 0)) {
        getDocComponents();
      }
    }

    watchEffect(() => {
      if (props.doc.comps && props.doc.comps.length > 0) {
        showCompsArea.value = true
      }
    })

    return {
      store,
      currentCompLoading,
      showCompsArea,
      dateConverter,
      getDocComponents,
      toggleCompsArea
    }
  },
})
</script>

<style lang="scss" scoped>
.doc-row:hover {
  background: #f8f9fa
}

.doc-titel:hover {
  cursor: pointer;
  text-decoration: underline;
}

</style>