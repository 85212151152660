import { Comp } from "./CompModels";

export class GetDocsRequest {
  archivid: number
  ordid: string

  constructor (archivid: number, ordid: string) {
    this.archivid = archivid;
    this.ordid = ordid;
  }
}

export class Doc {
  id: number
  docid: string
  ordid: string
  titel: string
  titel_1: string
  titel_2: string
  titel_3: string
  titel_4: string
  titel_5: string
  adate: Date
  mdate: Date
  ddate: Date | null
  alversion: string
  protection: string
  barcode: string
  autor: string
  comps: Array<Comp>

  constructor () {
    this.id = 0;
    this.docid = "";
    this.ordid = "";
    this.titel = "";
    this.titel_1 = "";
    this.titel_2 = "";
    this.titel_3 = "";
    this.titel_4 = "";
    this.titel_5 = "";
    this.adate = new Date();
    this.mdate = new Date();
    this.ddate = new Date();
    this.alversion = "";
    this.protection = "";
    this.barcode = "";
    this.autor = "";
    this.comps = new Array<Comp>();
  }
}
