import { ActionContext, ActionTree } from "vuex";

import { RootState } from '@/store';

import { State } from "./state"
import { Mutations, MutationTypes } from "./mutations";
import { Actions as OrdnerActions, ActionTypes as OrdnerActionTypes } from "../Ordner/actions";
import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions";

import { GetDocsRequest } from "@/models/DocModels";
import ArchivApiService from "@/service/ArchivApiService";

export enum ActionTypes {
  GetDocs = "GETDOCS"
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetDocs](context: ActionArguments, data: GetDocsRequest): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.GetDocs]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status
    status.docLoading = true
    status.docErrorMsg == ""
    dispatch(CommonActionTypes.SetStatus, status);
    
    ArchivApiService.getDocs(data)
    .then(res => {
      var docs: Array<Document> = res.data

      dispatch(OrdnerActionTypes.SetOrdnerDocs, docs)
      status.docLoading = false
      dispatch(CommonActionTypes.SetStatus, status);
    })
    .catch(error => {
      console.log(error);
      status.docLoading = true
      status.docErrorMsg == error.toString()
      dispatch(CommonActionTypes.SetStatus, status);
    })
  }
}