import { Ordner } from "@/models/OrdnerModels";
import { Doc } from "@/models/DocModels";
import { MutationTree } from "vuex";
import { State } from "./state";
import { Comp } from "@/models/CompModels";

export enum MutationTypes {
  GetOrdnerSuccess = "GETORDNERSUCCESS",
  SetOrdnerDocs = "SETORDNERDOCS",
  SetOrdnerDocComps = "SETORDNERDOCCOMPS",
  SetCurrentOrdner = "SETCURRENTORDNER",
  SetCurrentOrdnerFromSidebar = "SETCURRENTORDNERFROMSIDEBAR",
  ClearOrdner = "CLEARORDNER",
  ClearCurrentOrdner = "CLEARCURRENTORDNER",
  CreateOrdnerSuccess = "CREATEORDNERSUCCESS",
  UpdateOrdnerSuccess = "UPDATEORDNERSUCCESS",
  DeleteOrdnerSuccess = "DELETEORDNERSUCCESS"
}


export type Mutations = {
  [MutationTypes.GetOrdnerSuccess](state: State, ordner: Array<Ordner>): void
  [MutationTypes.SetOrdnerDocs](state: State, docs: Array<Doc>): void
  [MutationTypes.SetOrdnerDocComps](state: State, comps: Array<Comp>): void
  [MutationTypes.SetCurrentOrdner](state: State, ordner: Ordner): void
  [MutationTypes.SetCurrentOrdnerFromSidebar](state: State, ordner: Ordner): void
  [MutationTypes.ClearOrdner](state: State): void
  [MutationTypes.ClearCurrentOrdner](state: State): void
  [MutationTypes.CreateOrdnerSuccess](state: State, ordner: Ordner): void
  [MutationTypes.UpdateOrdnerSuccess](state: State, ordner: Ordner): void
  [MutationTypes.DeleteOrdnerSuccess](state: State, ordid: string): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.GetOrdnerSuccess](state, ordner) {
    if (ordner != null && ordner.length > 0) {
      if (state.ordner == null || state.ordner.length == 0) {
        state.ordner = ordner
      }
      else {
        // search parent
        const ownerid = ordner[0].ownerid;
        if (ownerid != "") {
          putInParentFolder(state.ordner, ordner);
        }
        else {
          state.ordner.push(...ordner);
        }
      }
    }
  },

  [MutationTypes.SetOrdnerDocs](state, docs) {
    if (state.currentOrdner && state.currentOrdner.length > 0) {
      state.currentOrdner[state.currentOrdner.length - 1].docs = docs;
    }
  },

  [MutationTypes.SetOrdnerDocComps](state, comps) {
    state.currentOrdner[state.currentOrdner.length - 1].docs.forEach(doc => {
      if (doc.docid == comps[0].docid) {
        doc.comps = comps
      }
    })
  },

  [MutationTypes.SetCurrentOrdner](state, ordner) {
    if (!state.currentOrdner.includes(ordner)) {
      state.currentOrdner.push(ordner);
    }
    else {
      const index = state.currentOrdner.indexOf(ordner);
      for(let i = state.currentOrdner.length; i > index + 1; i--) {
        state.currentOrdner.pop();
      }
    }
  },

  [MutationTypes.SetCurrentOrdnerFromSidebar](state, ordner) {
    // ordner ist Root, dann currentOrdner Array löschen und Root setzen
    if (ordner.ownerid == "") {
      state.currentOrdner = new Array<Ordner>();
      state.currentOrdner.push(ordner)
    }
    
    // ordner ist bereits in currentOrdner Array, lösche alle Unterverzeichnisse des Ordners aus currentOrdner Array
    else if (state.currentOrdner.includes(ordner)) {
      const index = state.currentOrdner.indexOf(ordner);
      for(let i = state.currentOrdner.length; i > index + 1; i--) {
        state.currentOrdner.pop();
      }
    }

    else {
      const parentOrdner = getParentFolder(state.ordner, ordner);

      // parent Ordner ist in currentOrdner Array
      if (parentOrdner && state.currentOrdner.includes(parentOrdner)) {
        const index = state.currentOrdner.indexOf(parentOrdner);
        for(let i = state.currentOrdner.length; i > index + 1; i--) {
          state.currentOrdner.pop();
        }
        state.currentOrdner.push(ordner)
      }

      // Ordner und Parent nicht currentOrdner Array
      else {
        const parentOrdnerArray = getFolderPathArray(state.ordner, ordner)
        state.currentOrdner = parentOrdnerArray.reverse();
      }
    }
  },

  [MutationTypes.ClearOrdner](state) {
    state.ordner = new Array<Ordner>();
    state.currentOrdner = new Array<Ordner>();
  },

  [MutationTypes.ClearCurrentOrdner](state) {
    state.currentOrdner = new Array<Ordner>();
  },

  [MutationTypes.CreateOrdnerSuccess](state, ordner) {
    const ordnerArray = new Array<Ordner>();
    ordnerArray.push(ordner)

    if (state.ordner == null || state.ordner.length == 0) {
      state.ordner = ordnerArray
    }
    else {
      // search parent
      const ownerid = ordner.ownerid;
      if (ownerid != "") {
        addToParentFolder(state.ordner, ordnerArray);
      }
      else {
        state.ordner.push(ordner);
      }
    }
  },

  [MutationTypes.UpdateOrdnerSuccess](state, ordner) {
    removeFolder(state.ordner, ordner.ordid)

    const ordnerArray = new Array<Ordner>();
    ordnerArray.push(ordner)
    const ownerid = ordner.ownerid;
    if (ownerid != "") {
      addToParentFolder(state.ordner, ordnerArray);
    }
    else {
      state.ordner.push(ordner);
    }
  },

  [MutationTypes.DeleteOrdnerSuccess](state, ordid) {
    removeFolder(state.ordner, ordid)
  },
}

// replaces Subfolder List (when folder tree gets build or updated)
function putInParentFolder(foldersArray: Array<Ordner>, ordner: Array<Ordner>): void {
  for (const folder of foldersArray) {
    if (folder.ordid == ordner[0].ownerid) {
      folder.ordner = ordner;
      return;
    }
    else {
      if (folder.ordner != null && folder.ordner != undefined && folder.ordner.length > 0) {
        putInParentFolder(folder.ordner, ordner);
      }
    }
  }
}

// adds Folderlist to Subfolders (when new folders are added)
function addToParentFolder(foldersArray: Array<Ordner>, ordner: Array<Ordner>): void {
  foldersArray.forEach(folder => {
    if (folder.ordid == ordner[0].ownerid) {
      folder.ordner.push(...ordner);
      return;
    }
    else if (folder.ordner != null && folder.ordner.length > 0) {
      addToParentFolder(folder.ordner, ordner)
    }
  })
}

function removeFolder(foldersArray: Array<Ordner>, ordid: string): void {
  const index = foldersArray.findIndex(f => f.ordid == ordid)
  if (index != -1) {
    foldersArray.splice(index, 1)
    return
  }
  else {
    foldersArray.forEach(folder => {
      if (folder.ordner != null && folder.ordner.length > 0) {
        removeFolder(folder.ordner, ordid)
      }
    })
  }
}

function getParentFolder(foldersArray: Array<Ordner>, ordner: Ordner): Ordner | undefined {
  if (foldersArray.length == 0) {
    return undefined
  }

  let response = foldersArray.find(ord => ord.ordid == ordner.ownerid)
  if (response != undefined) {
    return response
  }

  for (var ord of foldersArray){
    if (response != undefined) {
      break
    }

    response = getParentFolder(ord.ordner, ordner)
  }

  return response
}

function getFolderPathArray(foldersArray: Array<Ordner>, ordner: Ordner): Array<Ordner> {
  const response = new Array<Ordner>();
  response.push(ordner)

  var currentOrdner = ordner
  while (currentOrdner.ownerid != "") {
    var currentOrdnerParent = getParentFolder(foldersArray, currentOrdner)
    if (currentOrdnerParent != undefined) {
      currentOrdner = currentOrdnerParent
      response.push(currentOrdner)
    }
    else {
      break
    }
  }

  return response
}
