import { Doc } from "./DocModels";

export class GetOrdnerRequest {
  archivid: number
  ownerid: string

  constructor (archivid: number, ownerid: string) {
    this.archivid = archivid;
    this.ownerid = ownerid;
  }
}

export class Ordner {
  id: number
  ownerid: string
  ordid: string
  adate: Date
  mdate: Date
  ddate: Date | null
  autor: string
  titel: string
  titel_1: string
  titel_2: string
  titel_3: string
  titel_4: string
  titel_5: string
  barcode: string
  ordner: Array<Ordner>
  docs: Array<Doc>

  constructor () {
    this.id = 0;
    this.ownerid = "";
    this.ordid = "";
    this.adate = new Date();
    this.mdate = new Date();
    this.ddate = new Date();
    this.autor = "";
    this.titel = "";
    this.titel_1 = "";
    this.titel_2 = "";
    this.titel_3 = "";
    this.titel_4 = "";
    this.titel_5 = "";
    this.barcode = "";
    this.ordner = new Array<Ordner>();
    this.docs = new Array<Doc>();
  }
}


export class CreateOrdnerRequest {
  titel: string;
  ownerid: string;
  archivid: number;

  constructor(titel: string, ownerid: string, archivid: number) {
    this.titel = titel;
    this.ownerid = ownerid;
    this.archivid = archivid;
  }
}

export class UpdateOrdnerRequest {
  ordid: string;
  archivid: number;
  titel: string
  titel_1: string
  titel_2: string
  titel_3: string
  titel_4: string
  titel_5: string
  barcode: string
  // ddate: Date
  // edate: Date
  // vdate: Date

  constructor() {
    this.ordid = "";
    this.archivid = 0;
    this.titel = "";
    this.titel_1 = "";
    this.titel_2 = "";
    this.titel_3 = "";
    this.titel_4 = "";
    this.titel_5 = "";
    this.barcode = "";
  }
}

export class DeleteOrdnerRequest {
  ordid: string;
  archivid: number;

  constructor(ordid: string, archivid: number) {
    this.ordid = ordid;
    this.archivid = archivid;
  }
}