import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-863c09ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row p-2 folder-row" }
const _hoisted_2 = { class: "col-7" }
const _hoisted_3 = ["id"]
const _hoisted_4 = {
  key: 0,
  class: "spinner-border spinner-border-sm text-primary me-4"
}
const _hoisted_5 = { class: "col-1" }
const _hoisted_6 = { class: "dropdown" }
const _hoisted_7 = {
  type: "button",
  class: "btn btn-sm py-0 folder-menu-btn dropdown-toggle",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_8 = { class: "dropdown-menu m-0 p-0 rounded-0" }
const _hoisted_9 = { class: "col-2" }
const _hoisted_10 = { class: "col-2 text-end" }
const _hoisted_11 = ["id", "aria-labelledby"]
const _hoisted_12 = { class: "modal-dialog" }
const _hoisted_13 = { class: "modal-content" }
const _hoisted_14 = { class: "modal-header py-1" }
const _hoisted_15 = ["id"]
const _hoisted_16 = ["id"]
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "mb-3" }
const _hoisted_19 = ["for"]
const _hoisted_20 = ["id"]
const _hoisted_21 = { class: "mb-3" }
const _hoisted_22 = ["for"]
const _hoisted_23 = ["id"]
const _hoisted_24 = { class: "mb-3" }
const _hoisted_25 = ["for"]
const _hoisted_26 = ["id"]
const _hoisted_27 = { class: "mb-3" }
const _hoisted_28 = ["for"]
const _hoisted_29 = ["id"]
const _hoisted_30 = { class: "mb-3" }
const _hoisted_31 = ["for"]
const _hoisted_32 = ["id"]
const _hoisted_33 = { class: "mb-3" }
const _hoisted_34 = ["for"]
const _hoisted_35 = ["id"]
const _hoisted_36 = { class: "mb-3" }
const _hoisted_37 = ["for"]
const _hoisted_38 = ["id"]
const _hoisted_39 = {
  key: 2,
  class: "alert alert-danger"
}
const _hoisted_40 = {
  key: 0,
  class: "modal-footer"
}
const _hoisted_41 = ["id", "aria-labelledby"]
const _hoisted_42 = { class: "modal-dialog" }
const _hoisted_43 = { class: "modal-content" }
const _hoisted_44 = { class: "modal-header bg-danger text-white py-1" }
const _hoisted_45 = ["id"]
const _hoisted_46 = ["id"]
const _hoisted_47 = { key: 1 }
const _hoisted_48 = { class: "text-danger" }
const _hoisted_49 = {
  key: 2,
  class: "alert alert-danger"
}
const _hoisted_50 = {
  key: 0,
  class: "modal-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("input", {
          id: 'folder-row-select_' + _ctx.ord.id,
          class: "form-check-input me-4",
          type: "checkbox",
          value: ""
        }, null, 8, _hoisted_3),
        (_ctx.deleteOrdnerLoading && _ctx.thisOrdnerDeleteLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4))
          : (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 1,
              icon: "folder-open",
              class: "text-primary me-4"
            })),
        _createElementVNode("span", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.getOrdnerElements && _ctx.getOrdnerElements(...args))),
          class: "folder-titel me-4"
        }, _toDisplayString(_ctx.ord.titel), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", _hoisted_7, [
            _createVNode(_component_font_awesome_icon, {
              icon: "ellipsis",
              class: "text-body-tertiary"
            })
          ]),
          _createElementVNode("ul", _hoisted_8, [
            _createElementVNode("li", {
              class: "dropdown-item",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showUpdateModal()))
            }, " Bearbeiten "),
            _createElementVNode("li", {
              class: "dropdown-item",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDeleteModal()))
            }, " Löschen ")
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.ord.barcode), 1),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("span", null, _toDisplayString(_ctx.dateConverter), 1)
      ])
    ]),
    _createElementVNode("div", {
      class: "modal fade",
      id: 'updateOrdnerModal' + _ctx.ord.id,
      tabindex: "-1",
      "aria-labelledby": 'updateOrdnerModal' + _ctx.ord.id,
      "aria-hidden": "true",
      ref: "updateOrdnerModalRef"
    }, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("span", {
              class: "modal-title my-auto",
              id: 'updateOrdnerModalLabel' + _ctx.ord.id
            }, " Ordner bearbeiten ", 8, _hoisted_15),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close btn-sm",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeUpdateModal()))
            })
          ]),
          _createElementVNode("div", {
            class: "modal-body",
            id: 'updateOrdnerModalBody' + _ctx.ord.id
          }, [
            (_ctx.updateLoading)
              ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 0 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("label", {
                      for: 'updateFolderTitelInput_' + _ctx.ord.id,
                      class: "form-label"
                    }, "Titel", 8, _hoisted_19),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      id: 'updateFolderTitelInput_' + _ctx.ord.id,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.updateOrdner.titel) = $event))
                    }, null, 8, _hoisted_20), [
                      [_vModelText, _ctx.updateOrdner.titel]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("label", {
                      for: 'updateFolderTitel_1Input_' + _ctx.ord.id,
                      class: "form-label"
                    }, "Titel 1", 8, _hoisted_22),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      id: 'updateFolderTitel_1Input_' + _ctx.ord.id,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.updateOrdner.titel_1) = $event))
                    }, null, 8, _hoisted_23), [
                      [_vModelText, _ctx.updateOrdner.titel_1]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("label", {
                      for: 'updateFolderTitel_2Input_' + _ctx.ord.id,
                      class: "form-label"
                    }, "Titel 2", 8, _hoisted_25),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      id: 'updateFolderTitel_2Input_' + _ctx.ord.id,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.updateOrdner.titel_2) = $event))
                    }, null, 8, _hoisted_26), [
                      [_vModelText, _ctx.updateOrdner.titel_2]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("label", {
                      for: 'updateFolderTitel_3Input_' + _ctx.ord.id,
                      class: "form-label"
                    }, "Titel 3", 8, _hoisted_28),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      id: 'updateFolderTitel_3Input_' + _ctx.ord.id,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.updateOrdner.titel_3) = $event))
                    }, null, 8, _hoisted_29), [
                      [_vModelText, _ctx.updateOrdner.titel_3]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("label", {
                      for: 'updateFolderTitel_4Input_' + _ctx.ord.id,
                      class: "form-label"
                    }, "Titel 4", 8, _hoisted_31),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      id: 'updateFolderTitel_4Input_' + _ctx.ord.id,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.updateOrdner.titel_4) = $event))
                    }, null, 8, _hoisted_32), [
                      [_vModelText, _ctx.updateOrdner.titel_4]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_33, [
                    _createElementVNode("label", {
                      for: 'updateFolderTitel_5Input_' + _ctx.ord.id,
                      class: "form-label"
                    }, "Titel 5", 8, _hoisted_34),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      id: 'updateFolderTitel_5Input_' + _ctx.ord.id,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.updateOrdner.titel_5) = $event))
                    }, null, 8, _hoisted_35), [
                      [_vModelText, _ctx.updateOrdner.titel_5]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_36, [
                    _createElementVNode("label", {
                      for: 'updateFolderBarcodeInput_' + _ctx.ord.id,
                      class: "form-label"
                    }, "Barcode", 8, _hoisted_37),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      id: 'updateFolderBarcodeInput_' + _ctx.ord.id,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.updateOrdner.barcode) = $event))
                    }, null, 8, _hoisted_38), [
                      [_vModelText, _ctx.updateOrdner.barcode]
                    ])
                  ])
                ])),
            (_ctx.updateErrorMsg != null && _ctx.updateErrorMsg != '' && _ctx.showUpdateErrorMsg)
              ? (_openBlock(), _createElementBlock("div", _hoisted_39, " Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut. "))
              : _createCommentVNode("", true)
          ], 8, _hoisted_16),
          (!_ctx.updateLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-secondary btn-sm",
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.closeUpdateModal()))
                }, " Abbrechen "),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary btn-sm",
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.saveChanges()))
                }, " Änderungen speichern ")
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ], 8, _hoisted_11),
    _createElementVNode("div", {
      class: "modal fade",
      id: 'deleteOrdnerModal' + _ctx.ord.id,
      tabindex: "-1",
      "aria-labelledby": 'deleteOrdnerModal' + _ctx.ord.id,
      "aria-hidden": "true",
      ref: "deleteOrdnerModalRef"
    }, [
      _createElementVNode("div", _hoisted_42, [
        _createElementVNode("div", _hoisted_43, [
          _createElementVNode("div", _hoisted_44, [
            _createElementVNode("strong", {
              class: "modal-title my-auto",
              id: 'deleteOrdnerModalLabel' + _ctx.ord.id
            }, " Ordner löschen ", 8, _hoisted_45),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close btn-sm",
              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.closeDeleteModal()))
            })
          ]),
          _createElementVNode("div", {
            class: "modal-body",
            id: 'deleteOrdnerModalBody' + _ctx.ord.id
          }, [
            (_ctx.deleteOrdnerLoading)
              ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 0 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_47, [
                  _createElementVNode("p", null, [
                    _createTextVNode("Der Ordner "),
                    _createElementVNode("strong", _hoisted_48, _toDisplayString(_ctx.ord.titel), 1),
                    _createTextVNode(" sowie alle Unterordner und Dokumente werden gelöscht")
                  ])
                ])),
            (_ctx.deleteOrdnerErrorMsg != null && _ctx.deleteOrdnerErrorMsg != '' && _ctx.showDeleteErrorMsg)
              ? (_openBlock(), _createElementBlock("div", _hoisted_49, " Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut. "))
              : _createCommentVNode("", true)
          ], 8, _hoisted_46),
          (!_ctx.deleteOrdnerLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-secondary btn-sm",
                  onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.closeDeleteModal()))
                }, " Abbrechen "),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-danger btn-sm",
                  onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.deleteOrdner()))
                }, " Ordner löschen ")
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ], 8, _hoisted_41)
  ], 64))
}