import { createApp } from 'vue'
import App from './App.vue'

import router from "./router"
import {store} from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBars,
  faSearch,
  faArrowRightFromBracket,
  faArrowRightToBracket, 
  faBoxArchive,
  faFolderOpen,
  faFolder,
  faFile,
  faFileExcel,
  faFileWord,
  faFilePdf,
  faFilePowerpoint,
  faFileImage,
  faFileCsv,
  faChevronLeft,
  faChevronRight,
  faArrowDownLong,
  faArrowUpLong,
  faChevronDown,
  faCheck,
  faFileDownload,
  faMinus,
  faPlus,
  faPrint,
  faEnvelope,
  faEllipsis
} from '@fortawesome/free-solid-svg-icons'

import { faWindows } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faArrowDownLong,
  faArrowUpLong,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faBars,
  faBoxArchive,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faFileDownload,
  faFolderOpen,
  faFolder,
  faFile,
  faFileExcel,
  faFileWord,
  faFilePdf,
  faFilePowerpoint,
  faFileImage,
  faFileCsv,
  faEllipsis,
  faMinus,
  faPlus,
  faPrint,
  faSearch,
  faWindows
);

createApp(App)
  .use(store)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');

import "bootstrap"
