import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import ArchivApiService from "@/service/ArchivApiService";
import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions";

import { RootState } from '@/store';

import { State } from "./state"
import { DeleteOrdnerRequest, UpdateOrdnerRequest, CreateOrdnerRequest, GetOrdnerRequest, Ordner } from "@/models/OrdnerModels";
import { Doc } from "@/models/DocModels";
import { Comp } from "@/models/CompModels";

export enum ActionTypes {
  GetOrdner = "GETORDNER",
  GetOrdnerFromSidebar = "GETORDNERSIDEBAR",
  SetOrdnerDocs = "SETORDNERDOCS",
  SetOrdnerDocComps = "SETORDNERDOCCOMPS",
  SetCurrentOrdner = "SETCURRENTORDNER",
  SetCurrentOrdnerFromSidebar = "SETCURRENTORDNERFROMSIDEBAR",
  ClearOrdner = "CLEARORDNER",
  ClearCurrentOrdner = "CLEARCURRENTORDNER",
  CreateOrdner = "CREATEORDNER",
  UpdateOrdner = "UPDATEORDNER",
  DeleteOrdner = "DELETEORDNER"
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetOrdner](context: ActionArguments, ordnerRequest: GetOrdnerRequest): void
  [ActionTypes.GetOrdnerFromSidebar](context: ActionArguments, ordnerRequest: GetOrdnerRequest): Promise<void>
  [ActionTypes.SetOrdnerDocs](context: ActionArguments, docs: Array<Doc>): void
  [ActionTypes.SetOrdnerDocComps](context: ActionArguments, comps: Array<Comp>): void
  [ActionTypes.SetCurrentOrdner](context: ActionArguments, ordner: Ordner): void
  [ActionTypes.SetCurrentOrdnerFromSidebar](context: ActionArguments, ordner: Ordner): void
  [ActionTypes.ClearOrdner](context: ActionArguments): void
  [ActionTypes.ClearCurrentOrdner](context: ActionArguments): void
  [ActionTypes.CreateOrdner](context: ActionArguments, data: CreateOrdnerRequest): Promise<void>
  [ActionTypes.UpdateOrdner](context: ActionArguments, data: UpdateOrdnerRequest): Promise<void>
  [ActionTypes.DeleteOrdner](context: ActionArguments, data: DeleteOrdnerRequest): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.GetOrdner]({commit, dispatch, rootGetters}, ordnerRequest) {
    const status = rootGetters.status
    status.ordnerLoading = true
    status.ordnerErrorMsg == ""
    dispatch(CommonActionTypes.SetStatus, status);

    ArchivApiService.getOrdner(ordnerRequest)
      .then(res => {
        const ordner: Array<Ordner> = res.data;

        ordner.forEach(ord => {
          ord.ordner = new Array<Ordner>();
          ord.docs = new Array<Doc>();
        })

        commit(MutationTypes.GetOrdnerSuccess, ordner);
      })
      .catch(error => {
        status.ordnerErrorMsg == error.response.data.title
      })
      .finally(() => {
        status.ordnerLoading = false
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetOrdnerFromSidebar]({commit, dispatch, rootGetters}, ordnerRequest) {
    const status = rootGetters.status
    status.ordnerSidebarLoading = true
    status.ordnerSidebarLoadingOrdid = ordnerRequest.ownerid
    status.ordnerErrorMsg == ""
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.getOrdner(ordnerRequest)
      .then(res => {
        const ordner: Array<Ordner> = res.data;
        ordner.forEach(ord => {
          ord.ordner = new Array<Ordner>();
          ord.docs = new Array<Doc>();
        })
        commit(MutationTypes.GetOrdnerSuccess, ordner);
      })
      .catch(error => {
        status.ordnerErrorMsg == error.response.data.title
      })
      .finally(() => {
        status.ordnerSidebarLoading = false
        status.ordnerSidebarLoadingOrdid = ""
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  
  [ActionTypes.SetOrdnerDocs]({commit}, docs) {
    commit(MutationTypes.SetOrdnerDocs, docs);
  },
  
  [ActionTypes.SetOrdnerDocComps]({commit}, comps) {
    commit(MutationTypes.SetOrdnerDocComps, comps);
  },

  [ActionTypes.SetCurrentOrdner]({commit}, ordner) {
    commit(MutationTypes.SetCurrentOrdner, ordner);
  },

  [ActionTypes.SetCurrentOrdnerFromSidebar]({commit}, ordner) {
    commit(MutationTypes.SetCurrentOrdnerFromSidebar, ordner);
  },

  [ActionTypes.ClearOrdner]({commit}) {
    commit(MutationTypes.ClearOrdner, undefined);
  },

  [ActionTypes.ClearCurrentOrdner]({commit}) {
    commit(MutationTypes.ClearCurrentOrdner, undefined);
  },

  async [ActionTypes.CreateOrdner]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status
    status.createOrdnerLoading = true
    status.createOrdnerErrorMsg == ""
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.createOrdner(data)
    .then(res => {
      const ordner: Ordner = res.data;
      ordner.ordner = new Array<Ordner>();
      ordner.docs = new Array<Doc>();

      commit(MutationTypes.CreateOrdnerSuccess, ordner);
    })
    .catch(error => {
      console.log(error);
      status.createOrdnerErrorMsg = error.toString;
    })
    .finally(() => {
      status.createOrdnerLoading = false;
      dispatch(CommonActionTypes.SetStatus, status);
    })
  },

  async [ActionTypes.UpdateOrdner]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status
    status.updateOrdnerLoading = true
    status.updateOrdnerErrorMsg == ""
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.updateOrdner(data)
    .then(res => {
      const ordner: Ordner = res.data;
      ordner.ordner = new Array<Ordner>();
      ordner.docs = new Array<Doc>();

      commit(MutationTypes.UpdateOrdnerSuccess, ordner);
    })
    .catch(error => {
      console.log(error);
      status.updateOrdnerErrorMsg = error.toString;
    })
    .finally(() => {
      status.updateOrdnerLoading = false;
      dispatch(CommonActionTypes.SetStatus, status);
    })
  },

  async [ActionTypes.DeleteOrdner]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status
    status.deleteOrdnerLoading = true
    status.deleteOrdnerErrorMsg == ""
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.deleteOrdner(data)
    .then(res => {
      commit(MutationTypes.DeleteOrdnerSuccess, data.ordid);
    })
    .catch(error => {
      console.log(error);
      status.deleteOrdnerErrorMsg = error.toString;
    })
    .finally(() => {
      status.deleteOrdnerLoading = false;
      dispatch(CommonActionTypes.SetStatus, status);
    })
  }
}