import { GetterTree } from "vuex";
import { State } from "./state";
import { Status } from "@/models/CommonModels";
import { RootState } from "@/store";


export type Getters = {
  status(state: State): Status
}

export const getters: GetterTree<State, RootState> & Getters = {
  status(state) {
    return state.status;
  }
}
