import { createStore, createLogger } from 'vuex';
// import createPersistedState from 'vuex-persistedstate';

import { store as archiv, ArchivStore, State as ArchivState} from '@/store/modules/Archiv';
import { store as auth, AuthStore, State as AuthState} from '@/store/modules/Auth';
import { store as common, CommonStore, State as CommonState} from '@/store/modules/Common';
import { store as comp, CompStore, State as CompState} from '@/store/modules/Comp';
import { store as doc, DocStore, State as DocState} from '@/store/modules/Doc';
import { store as ordner, OrdnerStore, State as OrdnerState} from '@/store/modules/Ordner';
import { store as session, SessionStore, State as SessionState} from '@/store/modules/Session';
import { store as user, UserStore, State as UserState} from '@/store/modules/User';

export type RootState = {
  archiv: ArchivState;
  auth: AuthState;
  common: CommonState;
  comp: CompState;
  doc: DocState;
  ordner: OrdnerState;
  session: SessionState;
  user: UserState;
};

export type Store = ArchivStore<Pick<RootState, 'archiv'>>
  & AuthStore<Pick<RootState, "auth">>
  & CommonStore<Pick<RootState, "common">>
  & CompStore<Pick<RootState, "comp">>
  & DocStore<Pick<RootState, "doc">>
  & OrdnerStore<Pick<RootState, "ordner">>
  & SessionStore<Pick<RootState, "session">>
  & UserStore<Pick<RootState, "user">>;

const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

// plugins.push(createPersistedState({ storage: window.sessionStorage }));

export const store = createStore({
  plugins,
  modules: {
    archiv,
    auth,
    common,
    comp,
    doc,
    ordner,
    session,
    user,
  },
});

export function useStore(): Store {
  return store as Store;
}