<template>
  <!-- <h1 class="mb-4 fs-4">{{ name }}</h1> -->

  <div class="mb-4 pb-3 border-bottom">
    <button type="button" class="btn btn-primary btn-sm px-3" @click="showNewFolderModal()"><font-awesome-icon icon="plus" class="fa-sm me-2"/>Neu</button>
  </div>

  <div class="mb-4">
    <span v-if="currentOrdner.length == 0" class="fw-bold">{{ name }} </span>
    <span v-if="currentOrdner.length > 0">
      <span @click="backToRoot()" class="breadcrum-item">{{ name }} </span>
      <span v-for="ord, idx in currentOrdner" :key="ord.id">
        <span v-if="idx == currentOrdner.length - 1"> > <span class="fw-bold">{{ ord.titel }}</span></span>
        <span v-else @click="backToOrdner(ord)" class="breadcrum-item"> > {{ ord.titel }}</span>
      </span>
    </span>
  </div>

  <div class="bg-white shadow">
    <div class="border-bottom ">
      <div class="row px-2">
        <div class="col-8 py-2 table-col">
          <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">

            <input id="select-all-elements-input" class="form-check-input me-4" type="checkbox" value="">
            <font-awesome-icon icon="file" class="text-secondary me-4"/>
            <span class="fw-bold me-1">Titel</span>
            <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'titelasc'" class="text-secondary table-sort-icon"/>
            <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'titeldesc'" class="text-secondary table-sort-icon"/>

            <ul class="dropdown-menu m-0 ms-5 p-0 rounded-0">
              <li class="dropdown-item select-sort" @click="sortBy('titelasc')">
                <div class="row">
                  <div class="col-2">
                    <font-awesome-icon icon="check" v-show="sortByValue == 'titelasc'" />
                  </div>
                  <div class="col">
                    A bis Z
                  </div>
                </div>
              </li>
              <li class="dropdown-item select-sort" @click="sortBy('titeldesc')">
                <div class="row">
                  <div class="col-2">
                    <font-awesome-icon icon="check" v-show="sortByValue == 'titeldesc'" />
                  </div>
                  <div class="col">
                    Z bis A
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-2 py-2 table-col">
          <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">

            <span class="fw-bold me-1">Barcode</span>
            <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'barcodeasc'" class="text-secondary table-sort-icon"/>
            <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'barcodedesc'" class="text-secondary table-sort-icon"/>
            
            <ul class="dropdown-menu m-0 p-0 rounded-0">
              <li class="dropdown-item select-sort" @click="sortBy('barcodeasc')">
                <div class="row">
                  <div class="col-2">
                    <font-awesome-icon icon="check" v-show="sortByValue == 'barcodeasc'" />
                  </div>
                  <div class="col">
                    A bis Z
                  </div>
                </div>
              </li>
              <li class="dropdown-item select-sort" @click="sortBy('barcodedesc')">
                <div class="row">
                  <div class="col-2">
                    <font-awesome-icon icon="check" v-show="sortByValue == 'barcodedesc'" />
                  </div>
                  <div class="col">
                    Z bis A
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-2 py-2 text-end table-col">
          <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">

            <span class="fw-bold me-1">Datum</span>
            <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'datumasc'" class="text-secondary table-sort-icon"/>
            <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'datumdesc'" class="text-secondary table-sort-icon"/>

            <ul class="dropdown-menu m-0 p-0 rounded-0">
              <li class="dropdown-item select-sort" @click="sortBy('datumasc')">
                <div class="row">
                  <div class="col-2">
                    <font-awesome-icon icon="check" v-show="sortByValue == 'datumasc'" />
                  </div>
                  <div class="col">
                    Älteste oben
                  </div>
                </div>
              </li>
              <li class="dropdown-item select-sort" @click="sortBy('datumdesc')">
                <div class="row">
                  <div class="col-2">
                    <font-awesome-icon icon="check" v-show="sortByValue == 'datumdesc'" />
                  </div>
                  <div class="col">
                    Neueste oben
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>


    <LoadingSpinner v-if="ordnerLoading || docLoading" text="...Ordner und Dokumente werden geladen..." class="pb-5"/>

    <div v-if="!ordnerLoading && !docLoading && currentOrdner.length == 0">
      <div v-for="ord, idx in sortedOrdner" :key="ord.id">
        <div :class="{'border-bottom': idx != ordner.length - 1}">
          <OrdnerComp :ord="ord" :archivid="parseInt(id)" />
        </div>
      </div>
    </div>

    <div v-else-if="!ordnerLoading && !docLoading && currentOrdner.length > 0">
      <div v-if="displayedOrdner.length == 0 && (currentOrdner[currentOrdner.length - 1].docs.length == 0)" class="px-5 py-3">
        <div class="alert alert-warning">Es wurden keine Ordner und keine Dokumente gefunden.</div>
      </div>  
      <!-- <div v-for="ord, idx in currentOrdner[currentOrdner.length - 1].ordner" :key="ord.id"> -->
      <div v-for="ord, idx in displayedOrdner" :key="ord.id">
        <div :class="{'border-bottom': idx != currentOrdner[currentOrdner.length - 1].ordner.length - 1 || currentOrdner[currentOrdner.length - 1].docs.length > 0}">
          <OrdnerComp :ord="ord" :archivid="parseInt(id)" />
        </div>
      </div>

      <div v-if="(currentOrdner[currentOrdner.length - 1].docs != null && currentOrdner[currentOrdner.length - 1].docs != undefined) && currentOrdner[currentOrdner.length - 1].docs.length > 0">
        <!-- <div v-for="doc, idx in currentOrdner[currentOrdner.length - 1].docs.sort((a, b) => a.titel.toLowerCase() < b.titel.toLowerCase() ? -1 : 1)" :key="doc.id"> -->
        <div v-for="doc, idx in displayDocs" :key="doc.id">
          <div :class="{'border-bottom': idx != currentOrdner[currentOrdner.length - 1].docs.length - 1}">
            <Dokument :doc="doc" :archivid="parseInt(id)" :archivname="archiv?.archivname" :mandantid="archiv?.mandantid"/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- New Folder Modal -->
  <div
    class="modal fade"
    id="newFolderModal"
    tabindex="-1"
    aria-labelledby="newFolderModal"
    aria-hidden="true"
    ref="newFolderModalRef"
    >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header py-1">
          <span class="modal-title my-auto" id="newFolderModalLabel">Ordner erstellen</span>
          <button type="button" class="btn-close" aria-label="Close" @click="closeNewFolderModal()"></button>
        </div>
        <div class="modal-body">
          <LoadingSpinner v-if="createOrnderLoading" text="...Ordner wird erstellt..." />
          
          <div v-else class="mb-3">
            <label for="newFolderTitelInput" class="form-label">Titel</label>
            <input type="text" class="form-control" id="newFolderTitelInput" v-model="newFolderTitel">
          </div>
          <p class="text-danger" v-show="newFolderTitelError != ''">{{ newFolderTitelError }}</p>
        </div>
        <div class="modal-footer py-1">
          <button type="button" class="btn btn-outline-primary btn-sm" @click="createNewOrdner()">Erstellen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent } from "vue";
import { useStore } from '@/store'
import { useRoute } from "vue-router";

import { Modal } from 'bootstrap';

import { ActionTypes as OrdnerActionTypes } from "@/store/modules/Ordner/actions";
import { ActionTypes as ArchivActionTypes } from "@/store/modules/Archiv/actions";
import { CreateOrdnerRequest, GetOrdnerRequest, Ordner } from "@/models/OrdnerModels";

import OrdnerComp from '@/components/Archiv/OrdnerComp.vue'
import Dokument from '@/components/Archiv/Dokument.vue'
import LoadingSpinner from "@/components/LoadingSpinner.vue"

export default defineComponent ({
  name: "Archiv",

  components: {
    LoadingSpinner,
    OrdnerComp,
    Dokument,
  },

  setup () {
    const store = useStore();
    const route = useRoute();

    const ordnerLoading = computed(() => store.getters.status.ordnerLoading)
    const docLoading = computed(() => store.getters.status.docLoading)
    const archivErrorMsg = computed(() => store.getters.status.ordnerErrorMsg)

    const archive = computed(() => store.getters.archive)
    const archiv = computed(() => archive.value.find(obj => obj.id == parseInt(id.value) ))
    const name = ref("");
    const id = ref("");

    const sortByValue = ref("titelasc")
    const sortAsc = ref(true)
    const filter = ref("")

    const ordner = computed(() => store.getters.ordner);
    const sortedOrdner = computed(() => {
      return ordner.value
      .filter(a => a.titel.trim().toLowerCase().includes(filter.value.trim()))
      .sort((a, b) => {
        if (sortByValue.value == "titeldesc" || sortByValue.value == "titelasc") {
          var titleComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase())
          if (sortByValue.value == "titeldesc") {
            titleComparison = -1 * titleComparison
          }
          
          if (titleComparison == 0) {
            var dateComparison = a.mdate.toString().localeCompare(b.mdate.toString())
            return dateComparison
          }
          
          return titleComparison
        }

        else if (sortByValue.value == "barcodedesc" || sortByValue.value == "barcodeasc") {
          if (!a.barcode || !b.barcode) {
            return 0
          }

          var barcodeComparison = a.barcode.trim().toLowerCase().localeCompare(b.barcode.trim().toLowerCase())
          if (sortByValue.value == "barcodedesc") {
            barcodeComparison *= -1
          }

          if (barcodeComparison == 0) {
            var dateComparison = a.mdate.toString().localeCompare(b.mdate.toString())
            return dateComparison
          }

          return barcodeComparison
        }

        else if (sortByValue.value == "datumdesc" || sortByValue.value == "datumasc") {
          if (sortByValue.value == "datumasc" && a.ddate != null && b.ddate != null) {
            return a.ddate < b.ddate ? -1 : 1
          }
          else if (sortByValue.value == "datumdesc" && a.ddate != null && b.ddate != null) {
            return a.ddate < b.ddate ? 1 : -1
          }
          else {
            return 0
          }

          // if (sortByValue.value == "datumasc") {
          //   return a.mdate < b.mdate ? -1 : 1
          // }
          // else {
          //   return a.mdate < b.mdate ? 1 : -1
          // }
        }

        return 0
      })
    })

    const currentOrdner = computed(() => store.getters.currentOrdner);
    
    const displayedOrdner = computed(() => {
      return currentOrdner.value[currentOrdner.value.length - 1].ordner
      .filter(a => a.titel.trim().toLowerCase().includes(filter.value.trim()))
      .sort((a, b) => {
        if (sortByValue.value == "titeldesc" || sortByValue.value == "titelasc") {
          var titleComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase())
          if (sortByValue.value == "titeldesc") {
            titleComparison = -1 * titleComparison
          }
          
          if (titleComparison == 0) {
            var dateComparison = a.mdate.toString().localeCompare(b.mdate.toString())
            return dateComparison
          }
          
          return titleComparison
        }

        else if (sortByValue.value == "barcodedesc" || sortByValue.value == "barcodeasc") {
          if (!a.barcode || !b.barcode) {
            return 0
          }

          var barcodeComparison = a.barcode.trim().toLowerCase().localeCompare(b.barcode.trim().toLowerCase())
          if (sortByValue.value == "barcodedesc") {
            barcodeComparison *= -1
          }

          if (barcodeComparison == 0) {
            var dateComparison = a.mdate.toString().localeCompare(b.mdate.toString())
            return dateComparison
          }

          return barcodeComparison
        }

        else if (sortByValue.value == "datumdesc" || sortByValue.value == "datumasc") {
          if (sortByValue.value == "datumasc" && a.ddate != null && b.ddate != null) {
            return a.ddate < b.ddate ? -1 : 1
          }
          else if (sortByValue.value == "datumdesc" && a.ddate != null && b.ddate != null) {
            return a.ddate < b.ddate ? 1 : -1
          }
          else {
            return 0
          }

          // if (sortByValue.value == "datumasc") {
          //   return a.mdate < b.mdate ? -1 : 1
          // }
          // else {
          //   return a.mdate < b.mdate ? 1 : -1
          // }
        }

        return 0
      })
    })
    
    const displayDocs = computed(() =>  {
      return currentOrdner.value[currentOrdner.value.length - 1].docs
      .filter(a => a.titel.trim().toLowerCase().includes(filter.value.trim()))
      .sort((a, b) => {
        if (sortByValue.value == "titeldesc" || sortByValue.value == "titelasc") {
          var titleComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase())
          if (sortByValue.value == "titeldesc") {
            titleComparison = -1 * titleComparison
          }
          
          if (titleComparison == 0) {
            var dateComparison = a.mdate.toString().localeCompare(b.mdate.toString())
            return dateComparison
          }
          
          return titleComparison
        }

        else if (sortByValue.value == "barcodedesc" || sortByValue.value == "barcodeasc") {
          if (!a.barcode || !b.barcode) {
            return 0
          }

          var barcodeComparison = a.barcode.trim().toLowerCase().localeCompare(b.barcode.trim().toLowerCase())
          if (sortByValue.value == "barcodedesc") {
            barcodeComparison *= -1
          }

          if (barcodeComparison == 0) {
            var dateComparison = a.mdate.toString().localeCompare(b.mdate.toString())
            return dateComparison
          }

          return barcodeComparison
        }

        else if (sortByValue.value == "datumdesc" || sortByValue.value == "datumasc") {
          if (sortByValue.value == "datumasc" && a.ddate != null && b.ddate != null) {
            return a.ddate < b.ddate ? -1 : 1
          }
          else if (sortByValue.value == "datumdesc" && a.ddate != null && b.ddate != null) {
            return a.ddate < b.ddate ? 1 : -1
          }
          else {
            return 0
          }

          // if (sortByValue.value == "datumasc") {
          //   return a.mdate < b.mdate ? -1 : 1
          // }
          // else {
          //   return a.mdate < b.mdate ? 1 : -1
          // }
        }

        return 0
      })
    })

    const sortBy = (param: string) => {
      sortByValue.value = param
    }

    // New Folder Modal
    const createOrnderLoading = computed(() => store.getters.status.createOrdnerLoading)
    const createOrdnerErrorMsg = computed(() => store.getters.status.createOrdnerErrorMsg)
    const newFolderTitel = ref("Neuer Ordner");
    const newFolderTitelError = ref("")
    const newFolderModalRef = ref();
    function closeNewFolderModal () {
      newFolderTitel.value = "Neuer Ordner";
      newFolderTitelError.value = "";
      Modal.getInstance(newFolderModalRef.value)?.hide();
    }
    function showNewFolderModal () {
      if (newFolderModalRef.value) {
        new Modal(newFolderModalRef.value).show();
      }
    }

    return {
      store,
      route,
      ordnerLoading,
      docLoading,
      name,
      id,
      archive,
      archiv,
      ordner,
      sortedOrdner,
      currentOrdner,
      displayedOrdner,
      displayDocs,
      sortByValue,
      sortBy,
      createOrnderLoading,
      createOrdnerErrorMsg,
      newFolderTitel,
      newFolderTitelError,
      newFolderModalRef,
      closeNewFolderModal,
      showNewFolderModal,
    }
  },

  methods: {
    async getArchive(): Promise<void> {
      await this.store.dispatch(ArchivActionTypes.GetArchive, undefined);
      return new Promise((res, rej) => {res()})
    },

    getArchivOrdner() {
      this.store.dispatch(ArchivActionTypes.SetSelectedArchiv, this.archiv);
      this.store.dispatch(OrdnerActionTypes.ClearCurrentOrdner, undefined);
      this.store.dispatch(OrdnerActionTypes.ClearOrdner, undefined);
      this.store.dispatch(OrdnerActionTypes.GetOrdner, new GetOrdnerRequest(parseInt(this.id), ""));
    },

    backToOrdner(ordner: Ordner) {
      this.store.dispatch(OrdnerActionTypes.SetCurrentOrdner, ordner);
    },
    backToRoot() {
      this.store.dispatch(OrdnerActionTypes.ClearCurrentOrdner, undefined);
    },

    async createNewOrdner () {
      if (this.archiv != null) {
        if (this.newFolderTitel.trim() == "") {
          this.newFolderTitelError = "Der Titel darf nicht leer sein.";
        }
        else {
          let ownerid = "";

          if (this.currentOrdner.length > 0) {
            ownerid = this.currentOrdner[this.currentOrdner.length - 1].ordid;
          }

          var request = new CreateOrdnerRequest(this.newFolderTitel.trim(), ownerid.trim(), this.archiv.id);
          await this.store.dispatch(OrdnerActionTypes.CreateOrdner, request);

          if (this.createOrdnerErrorMsg != "") {
            this.newFolderTitelError = "Es ist ein Fehler aufgetreten";
          }
          else {
            this.closeNewFolderModal();
          }
        }
      }
      else {
        this.newFolderTitelError = "Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut."
      }
    }
  },

  async mounted () {
    if (this.archive.length == 0) {
      await this.getArchive()
    }

    if (this.name != this.route.params.name as string || this.id != this.route.query.id?.toString()) {
      this.name = this.$route.params['name'] as string
      if (this.route.query.id) {
        this.id = this.route.query.id.toString()
      }
      this.getArchivOrdner()
    }
  },

  async beforeUpdate () {
    if (this.archive.length == 0) {
      await this.getArchive()
    }

    if (this.name != this.route.params.name as string || this.id != this.route.query.id?.toString()) {
      this.name = this.$route.params['name'] as string
      if (this.route.query.id) {
        this.id = this.route.query.id.toString()
      }
      this.getArchivOrdner()
    }
  }
})
</script>

<style lang="scss" scoped>
.breadcrum-item {
  cursor: pointer;
}

.table-col:hover {
  background: rgb(216, 217, 222);
}

.table-sort-icon {
  font-size: .7em;
}

.select-sort:hover {
  cursor: pointer;
}
</style>